import { ThemeOptions } from '@material-ui/core/styles';
import { EvlMediaSwitcherProps } from 'evl-ui-components/lib/components/media-switcher/MediaSwitcher';
import { JSONSchema7 } from 'json-schema';

export enum Status {
  blank,
  requested,
  inProgress,
  success,
  failure,
  noContent,
}

export enum AccessLevel {
  customer,
  business,
  admin,
}

export interface DecodeVerifyJWTConfig {
  token: string;
  region: string;
  userpoolId: string;
  clientId: string;
}

export enum FromChangePasswordPanel {
  personalDetails,
  normalLogin,
}

export type DecodeVerifyJWT = (config: DecodeVerifyJWTConfig) => Promise<any>;

export interface AuthenticateUserConfig {
  username: string;
  password: string;
}

export interface ForgotPasswordConfig {
  phoneNumber: string;
}

export interface AttributesCollection {
  preferredUsername: string;
  name: string;
  phoneNumber: string;
  city: string;
  birthday: string;
  instagramHandle: string;
  [key: string]: string;
}

export interface CognitoFailure {
  code?: string;
  message: string;
  name: string;
}

export interface ForgotPasswordConfig {
  phoneNumber: string;
}

export interface ConfirmForgotPasswordConfig {
  newPassword: string;
  verificationCode: string;
  username: string;
}

export interface OptionType {
  label: string;
  value: string;
}

export interface Attribute {
  Name: string;
  Value: string;
}

export interface RegisterUserConfig {
  username: string;
  password: string;
  userAttributes: Attribute[];
}

export interface RegisterClickConfig {
  username: string;
  password: string;
  userAttributes: Attribute[];
}

export interface UpdateUserAttrsConfig {
  userAttributes: Attribute[];
}

export interface CountryCodeNew {
  label: string;
  shortLabel: string;
  flagCodePoint: string;
  country: string;
  countryAbbr: string;
  countryAbbr3: string;
  value: string;
}

export interface CountryCodes {
  code: string;
  label: string;
  phone: string;
}

export interface Options {
  name: string;
  dial_code: string;
  code: string;
  flag: string;
  coutryWithFlag: string;
  [key: string]: any;
}

export interface CountryCode {
  dial_code: string;
  code: string;
  flag: '';
}

export interface CountryCodeSelect {
  dial_code: string;
  value: string;
  label: string;
}

export enum Kind {
  AGGREGATION = 'aggregation',
  DISAGGREGATION = 'disaggregation',
  OBJECT = 'object',
  TRANSACTION = 'transaction',
  TRANSFORMATION = 'transformation',
}

export enum BlockType {
  TITLE = 'title',
  PARAGRAPH = 'paragraph',
  IMAGE = 'image',
  EVENT_CHAIN = 'event-chain',
  BUTTON = 'button',
  MEDIA_CARD = 'media-card',
  HANDLEBAR = 'handlebar',
  MEDIA = 'media',
  NFC_TAMPER_STATUS = 'nfc-tamper-status',
}

export interface TitleBlockData {
  text: string;
}
export interface ParagraphBlockData {
  text: string;
}
export interface ImageBlockData {
  imageUrls: Array<string>;
}

export interface EventChainBlockData {
  type: 'timeline' | 'list';
}

export interface NfcTamperStatusBlockData {}

export interface MediaCardBlockData {
  title: string;
  description: string;
  mediaUrls: string[];
}
export interface ButtonBlockData {
  type: 'primary' | 'secondary' | 'tertiary';
  href: 'string';
}

export enum TemplateStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface Block<T> {
  type: BlockType;
  data: T;
}

export type AnyBlock = Block<
  | TitleBlockData
  | ParagraphBlockData
  | ImageBlockData
  | EventChainBlockData
  | ButtonBlockData
  | MediaCardBlockData
  | EvlMediaSwitcherProps
  | NfcTamperStatusBlockData
>;

export interface Flow {
  _id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface Sku {
  imageUrls: string[];
  _id: string;
  skuSchema: string | SkuSchema;
  flow: string | Flow;
  details: {
    [key: string]: any;
  };
  createdAt: string;
  updatedAt: string;
}

export interface SkuSchema {
  _id: string;
  name: string;
  flow: string | Flow;
  dataSchema: JSONSchema7;
  createdAt: string;
  updatedAt: string;
}

export interface AssetSchema {
  _id: string;
  name: string;
  flow: string | Flow;
  skuSchema?: string | SkuSchema;
  dataSchema: JSONSchema7;
  createdAt: string;
  updatedAt: string;
}

export interface Asset {
  imageUrls: string[];
  _id: string;
  assetSchema: string | AssetSchema;
  flow: string | Flow;
  epc: string;
  ean: string | Sku;
  details: {
    [key: string]: any;
  };
  createdAt: string;
  updatedAt: string;
}

export enum ThemeStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface Theme {
  status: ThemeStatus;
  _id: string;
  name: string;
  flow: string | Flow;
  themeOptions: ThemeOptions;
  createdAt: string;
  updatedAt: string;
}

export interface EventSchema {
  editableAssetFields: string[];
  _id: string;
  name: string;
  flow: string | Flow;
  kind: Kind;
  dataSchema: JSONSchema7;
  assetSchema: string | AssetSchema;
  createdAt: string;
  updatedAt: string;
}

export interface Template {
  blocks: AnyBlock[];
  status: TemplateStatus;
  _id: string;
  name: string;
  assetSchema: string | AssetSchema;
  createdAt: string;
  updatedAt: string;
}

export interface NfcResponse {
  status: string;
  tamperStatus: string;
  timestampTag: number;
  uid: string;
}

export interface Story {
  flow: Flow;
  skuSchema?: SkuSchema;
  sku?: Sku;
  assetSchema: AssetSchema;
  asset: Asset;
  theme: Theme;
  template: Template;
  nfcResponse: NfcResponse;
}

export interface BulkClaimCard {
  assetImage?: string;
  brandImage?: string;
  productName?: string;
}
